import React, { Component } from 'react';
import VoiceControl from './oscillators/VoiceControl';
import VoicesToggleControl from './oscillators/VoicesToggleControl';
import EnvelopeControl from './envelopes/EnvelopeControl';
import ToneControl from './tone/ToneControl';
import { PowerSwitch } from './controls/ToggleSwitch';
import { VolumeControl } from './vol/VolumeControl';
import PresetControl from './presets/PresetControl';
import { RecSwitch } from './record/RecSwitch.js'
import { VuMeter } from './record/VuMeter';
import SwarmEngine from './utils/SwarmEngine';
import './Swarmatron.css';

class Swarmatron extends Component {
  state = { deviceOn: true }

  hideSwarm = () => {
    this.props.setSwarm(false);
  }

  componentWillMount() {
    this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
    this.swarmEngine = new SwarmEngine(this.audioContext);
  }

  componentDidMount() {
    this.swarmEngine.swarmVol.connect(this.swarmEngine.vuMeter.plugIn());
    document.addEventListener("touchstart", () => {
      document.activeElement.blur();
    });
  }

  componentWillUnmount() {
    this.audioContext.close();
  }

  togglePower = () => {
    const powerState = this.state.deviceOn;
    const { voices } = this.swarmEngine.voices;
    this.setState({ deviceOn: !this.state.deviceOn });
    if (!powerState) {
      voices.forEach((v, i) => {
        voices[i].voiceOn();
      });
    } else {
      voices.forEach((v, i) => {
        voices[i].voiceOff();
      });
      this.resetEnvelopes();
    }
  }

  resetEnvelopes = () => {
    var now = this.audioContext.currentTime;
    this.swarmEngine.envNode.gain.cancelScheduledValues(now);
    this.swarmEngine.envNode.gain.value = 0;
    this.swarmEngine.filter.detune.cancelScheduledValues(now);
    this.swarmEngine.filter.detune.value = 0;
  }

  render() {
    return (
      <div id="swarmatron">
        <div class="close" onClick={this.hideSwarm}></div>
        <form id="mainpanel" className="group">
          <label className="voices">VOICES 1 - 8 </label>
          <ul className="mainswitchrow group">
            <VolumeControl
              swarmVol={this.swarmEngine.swarmVol}
              setVolume={this.swarmEngine.setVol}
              volume={1}
            />
            <VoicesToggleControl
              voices={this.swarmEngine.voices}
              deviceOn={this.state.deviceOn}
            />
            <PowerSwitch togglePower={this.togglePower} />
          </ul>
          <ToneControl
            filter={this.swarmEngine.filter}
            filtenv={this.swarmEngine.filtenv}
            swOverdrive={this.swarmEngine.swOverdrive}
            distortionCurve={this.swarmEngine.distortionCurve} />

          <EnvelopeControl
            volumeEnv={this.swarmEngine.volumeEnv}
          />
          <RecSwitch
            deviceOn={this.state.deviceOn}
            recorder={this.swarmEngine.recorder}
          />
          <VuMeter audioContext={this.audioContext}
            ref={instance => { this.swarmEngine.vuMeter = instance; }}
          />
        </form>
        <div id="ribbonpanel" className="group">
          <PresetControl />
          <VoiceControl
            voices={this.swarmEngine.voices}
            volumeEnv={this.swarmEngine.volumeEnv}
            filtenv={this.swarmEngine.filtenv}
          />
        </div>
        <div class="explanation">
          <p>
            Click or tap the top green ribbon.  Dragging to the right will raise the center pitch of the resulting cluster of notes,
            dragging to the left will lower it.  To spread the cluster's notes, click and drag on the lower green ribbon.
          </p>
          <p>
            The Swarmatron was invented by Leon Dewan and Brian Dewan, co-founders of Dewanatron, an electronic music instrument
            company in operation since 2002.
            There are currently 85 Swarmatrons in existence, many of which have ended up in the hands of television and
            film composers who have gone on to use them extensively in their scores.</p>
          <p>
            This code example is an emulation of the Swarmatron written for the purpose of exploring the Web Audio API,
            using React and Redux.
          </p>
        </div>
      </div>
    );
  }
}

export default Swarmatron;