import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import Home from './Home.js';
import Swarmatron from './Swarmatron.js';
import './App.css';

class App extends Component {
  state = { showSwarm: false };

  setSwarm = (showSwarm) => {
    this.setState({ showSwarm });
  }

  render() {
    return (
      <div id="home">
        <div class="header">
          <div class="header-left">Leon Dewan</div>
          <div class="header-right">Front End Engineer +</div>
        </div>

        <CSSTransition
          in={this.state.showSwarm}
          timeout={300}
          classNames="swarm-home"
          unmountOnExit
        >
          <Swarmatron className="swarm-home" setSwarm={this.setSwarm} />
        </CSSTransition>
        <CSSTransition
          in={!this.state.showSwarm}
          timeout={300}
          classNames="home"
          unmountOnExit
        >
          <Home className="home" setSwarm={this.setSwarm} />
        </CSSTransition>
      </div>
    );
  }
}

export default App;
