import React, { Component } from 'react';

class Home extends Component {

  showSwarm = () => {
    this.props.setSwarm(true);
  }
  render() {
    return (
      <div>
        <div class="main">
          <div class="main-left">
            <div class="top">
              <p class="first">15+ years experience architecting, designing and executing
                front end web implementations both as solo
                developer and as part of large project teams.</p>

              <p>Co-founder of Dewanatron,
                a company specializing in the design and production
                of handmade analog synthesizers.</p>
            </div>

          </div>

          <div class="main-right">
            <div class="projects-header">
              Featured Project
            </div>

            <div class="project-block first">
              <div class="project-title">
                Web Audio Swarmatron
              </div>
              <div class="project-body widethumb">
                <div id="swarmthumb" onClick={this.showSwarm}></div>
                <div class="project-text">
                  <p>An emulation of the Dewanatron
                    Swarmatron built with React and Redux, using the Web Audio api.</p>
                  <p>See code on <a href="https://github.com/leondewan/react-redux-swarmatron"
                    target="_blank">Github.</a></p>
                </div>
              </div>
            </div>

            {/* <div class="project-block">
                            <div class="project-title">
                                Collage Kid Experimental Filmmaker
                            </div>
                            <div class="project-body">
                                <a id="ckthumb" href="https://itunes.apple.com/us/app/collage-kid/id1455787776?ls=1&mt=8"
                                target="_blank"></a>
                                <div class="project-text"><p>A mobile app for creating Surrealist/Dadaist
                                    experimental films based on video, images
                                    and sounds captured by the user.  It calculates
                                    and executes edits based on weather,
                                    stock market and planetary alignment data. </p>

                                    <p>See code on <a href="https://github.com/leondewan/collage-kid"
                                    target="_blank">Github.</a></p>
                                </div>
                            </div>
                        </div> */}
          </div>
        </div>
        <div class="bottom-nav">
          <ul>
            <li><a href="Leon_Dewan_FrontEnd_Engineer.pdf" download target="_blank">Resume</a></li>
            <li><a href="https://www.linkedin.com/in/leondewan" target="_blank">LinkedIn</a></li>
            <li><a href="mailto:leon.dewan@gmail.com">Contact</a></li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Home;
